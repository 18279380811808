import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
// import { alpha } from '@mui/material/styles';
// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { LoadingScreen } from 'src/components/loading-screen';
import { useGetAllSystemQuery } from 'src/store/Reducer/system';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'title', label: 'System' },
  { id: '', label: 'Action', width: 88 },
];

const defaultFilters = {
  title: '',

};

// ----------------------------------------------------------------------

export default function SystemListView() {

  const table = useTable();

  const [filters, setFilters] = useState(defaultFilters);

  const { data: systemData, isLoading } = useGetAllSystemQuery({
    pageno: table.page,
    limit: table.rowsPerPage,
    search: filters.title,
  })

  let { systems: data, total_length } = systemData ?? {};

  let count = 0;
  let total = table.page * table.rowsPerPage;


  const settings = useSettingsContext();
  const [tableData, setTableData] = useState(data);

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );


  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
              heading="System List"
              links={[
                { name: 'Dashboard', href: paths.dashboard.root },
                { name: 'System', href: paths.dashboard.system.root },
                { name: 'List' },
              ]}
              action={
                <Button
                  component={RouterLink}
                  href={paths.dashboard.system.new}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  Add new System
                </Button>
              }
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            />

            <Card>
              <UserTableToolbar
                filters={filters}
                onFilters={handleFilters}
                //
                roleOptions={_roles}
              />

              {canReset && (
                <UserTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  onResetFilters={handleResetFilters}
                  //
                  results={data?.length}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  // dense={table.dense}
                  numSelected={table.selected?.length}
                  rowCount={data?.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      data?.map((row, button) => row.id)
                    )
                  }
                // action={
                //   <Tooltip title="Delete">
                //     <IconButton color="primary" onClick={confirm.onTrue}>
                //       <Iconify icon="solar:trash-bin-trash-bold" />
                //     </IconButton>
                //   </Tooltip>
                // }
                />

                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={table.selected?.length}
                      onSort={table?.onSort}
                    />

                    <TableBody>
                      {data?.map(
                        (row, index) => (
                          (count = count + 1),
                          (
                            <UserTableRow
                              key={row._id}
                              row={row}
                              selected={table.selected.includes(row._id)}
                              onSelectRow={() => table.onSelectRow(row._id)}
                              index={index + 1}
                              counter={count + total}
                              // onDeleteRow={() => handleDeleteRow(row._id)}
                              // onEditRow={() => handleEditRow(row.id)}

                            />
                          )
                        )
                      )}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, total_length)}
                      />

                      {/* <TableNoData notFound={notFound} /> */}

                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={total_length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              // dense={table.dense}
              // onChangeDense={table.onChangeDense}
              />
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  // const { name, status, role } = filters;

  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  // if (name) {
  //   inputData = inputData?.filter(
  //     (user) => user && user.name && user.name.toLowerCase()?.indexOf(name.toLowerCase()) !== -1
  //   );
  // }

  // if (status !== 'all') {
  //   inputData = inputData?.filter((user) => user && user.status === status);
  // }

  // if (role.length) {
  //   inputData = inputData?.filter((user) => user && role.includes(user.role));
  // }

  return inputData;
}
