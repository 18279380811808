import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const bookingApi = createApi({
  reducerPath: 'booking',
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllBooking: builder.query({
      query: ({ pageno, limit, search }) =>
        `/booking/?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
    }),

    getProductById: builder.query({
      query: (id) => `/bookings/${id}`,
    }),

    // getBookingWithFilterPending: builder.query({
    //   query: (id) => `booking/filter?filter=pending`,
    //   transformResponse: (res) => res.body,
    // }),

    updateBooking: builder.mutation({
      query: (data) => (
        console.log('On API : ', data),
        { 
          url: `booking/assignagent/${data._id}`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: data.updatedData,
        }
      ),
    }),
  }),
});

export const { useGetAllBookingQuery, useGetProductByIdQuery, useUpdateBookingMutation } =
  bookingApi;
