import { Helmet } from 'react-helmet-async';
import SystemCardsView from 'src/sections/system/view/system-cards-view';
// sections


// ----------------------------------------------------------------------

export default function SystemCardsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: System Cards</title>
      </Helmet>

      <SystemCardsView />
    </>
  );
}
