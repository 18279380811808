import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const serviceApi = createApi({
  reducerPath: 'services',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Services'],
  endpoints: (builder) => ({
    getAllService: builder.query({
      query: ({ pageno, limit, search }) =>
        `/service/user?pageno=${pageno + 1}&limit=${limit}&search=${search}`,

      transformResponse: (res) => {
        return { total_pages: res.total_pages, data: res.body };
      },
      providesTags: ['Services'],
    }),

    getServicesForDropDown: builder.query({
      query: () => `service/all`,
      transformResponse: (res) => res.body,
    }),

    addNewService: builder.mutation({
      query: (newService) => ({
        url: `/service`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newService,
      }),
      invalidatesTags: ['Services'],
    }),

    getServicebySearch: builder.query({
      query: () => `/service/all`,
      transformResponse: (res) => res.body,
    }),

    updateService: builder.mutation({
      query: (data) => (
        console.log('Data on API = ', data),
        {
          url: `service/${data._id}`,
          method: 'Put',
          headers: { 'Content-Type': 'application/json' },
          body: data?.updatedData,
        }
      ),
      invalidatesTags: ['Services'],
    }),
  }),
});

export const {
  useGetAllServiceQuery,
  useAddNewServiceMutation,
  useUpdateServiceMutation,
  useGetServicebySearchQuery,
  useGetServicesForDropDownQuery,
} = serviceApi;
