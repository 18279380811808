import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const FileApi = createApi({
  reducerPath: "files",
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    uploadNewFile: builder.mutation({

      query: (fileData) => {
        const formData = new FormData();
        formData.append("file", fileData);
        return {
          url: "file", // Update the URL here
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (filename) => ({
        url: `/file/${filename}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useUploadNewFileMutation, useDeleteFileMutation } = FileApi;
