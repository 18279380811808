import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const regionApi = createApi({
    reducerPath: 'region',
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Region'],
    endpoints: (builder) => ({
        getAllRegion: builder.query({
            query: ({ pageno, limit, search }) => `region/all?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
            transformResponse: (res) => (res.body),
            providesTags: ['Region'],
        }),

        getRegionsDropdown: builder.query({
            query: () => `/region/all`,
            transformResponse: (res) => res.body,
        }),

        getRegionById: builder.query({
            query: (id) => `/region/single/${id}`,
            transformResponse: (res) => (res.body),
            providesTags:['Region'],
        }),

        addNewRegion: builder.mutation({
            query: (newregion) => 
                ({
                url: `/region/add`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: newregion,
            }),
            invalidatesTags: ['Region'],

        }),

        updateRegion: builder.mutation({
            query: (data) => (
                {
                    url: `region/update/${data?._id}`,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: data,
                }
            ),
            invalidatesTags: ['Region'],
        }),

        deleteRegion: builder.mutation({
            query: (id) =>
            (
                {
                    url: `/region/delete/${id}`,
                    method: "DELETE"
                }
            ),
            invalidatesTags:['Region']
        })
    }),
});

export const {
    useGetAllRegionQuery,
    useGetRegionsDropdownQuery,
    useAddNewRegionMutation,
    useGetRegionByIdQuery,
    useUpdateRegionMutation,
    useDeleteRegionMutation
} = regionApi;
