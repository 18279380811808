import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const categoryApi = createApi({
  reducerPath: 'category',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Category'],
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: ({ pageno, limit, search }) => `service/categories/user?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['Category'],
    }),

    getCategoriesDropdown: builder.query({
      query: () => `/service/categories/user`,
      transformResponse: (res) => res.body,
    }),

    addNewCategory: builder.mutation({
      query: (newCategory) => ({
        url: `/service/categories`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newCategory,
      }),
      invalidatesTags: ['Category'],
    }),

    updateCategory: builder.mutation({
      query: (data) => (
        {
          url: `service/categories/${data.id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        }
      ),
      invalidatesTags: ['Category'],
    }),
  }),
});

export const { useGetAllCategoryQuery, useGetCategoriesDropdownQuery, useAddNewCategoryMutation, useUpdateCategoryMutation, useGetCategoriesBySearchQuery } =
  categoryApi;
