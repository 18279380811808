import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const subscriptionApi = createApi({
  reducerPath: 'Subscription',
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllSubscribtion: builder.query({
        query: ({ pageno, limit, search }) => `/subscription/all?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
        transformResponse: (res) => (res.body),
    }),

    getSubscriptionById: builder.query({
      query: (id) => `/subscription/${id}`,
      transformResponse: (res) => (res.body)
    }),
  }),
});

export const {  useGetAllSubscribtionQuery,useGetSubscriptionByIdQuery } = subscriptionApi;
