import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const equipmentOrdersApi = createApi({
  reducerPath: "equipmentOrders",
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllEquipmentOrders: builder.query({
      query: ({ pageno, limit, search }) => `/order?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
    transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
    }),

  }),
});

export const {
  useGetAllEquipmentOrdersQuery,
} = equipmentOrdersApi;
