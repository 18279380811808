// assets
import { countries } from 'src/assets/data';
//
import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const USER_STATUS_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const _userAbout = {
  id: _mock.id(1),
  role: _mock.role(1),
  email: _mock.email(1),
  country: countries[1].label,
  school: _mock.companyName(2),
  company: _mock.companyName(1),
  coverUrl: _mock.image.cover(3),
  totalFollowers: _mock.number.nativeL(1),
  totalFollowing: _mock.number.nativeL(2),
  quote:
    'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
  socialLinks: {
    facebook: `https://www.facebook.com/caitlyn.kerluke`,
    instagram: `https://www.instagram.com/caitlyn.kerluke`,
    linkedin: `https://www.linkedin.com/in/caitlyn.kerluke`,
    twitter: `https://www.twitter.com/caitlyn.kerluke`,
  },
};

export const _userFollowers = [...Array(18)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  country: countries[index + 1].label,
  avatarUrl: _mock.image.avatar(index),
}));

export const _userFriends = [...Array(18)].map((_, index) => ({
  id: _mock.id(index),
  role: _mock.role(index),
  name: _mock.fullName(index),
  avatarUrl: _mock.image.avatar(index),
}));

export const _userGallery = [...Array(12)].map((_, index) => ({
  id: _mock.id(index),
  postedAt: _mock.time(index),
  title: _mock.postTitle(index),
  imageUrl: _mock.image.cover(index),
}));

export const _userFeeds = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  createdAt: _mock.time(index),
  media: _mock.image.travel(index + 1),
  message: _mock.sentence(index),
  personLikes: [...Array(20)].map((__, personIndex) => ({
    name: _mock.fullName(personIndex),
    avatarUrl: _mock.image.avatar(personIndex + 2),
  })),
  comments: (index === 2 && []) || [
    {
      id: _mock.id(7),
      author: {
        id: _mock.id(8),
        avatarUrl: _mock.image.avatar(index + 5),
        name: _mock.fullName(index + 5),
      },
      createdAt: _mock.time(2),
      message: 'Praesent venenatis metus at',
    },
    {
      id: _mock.id(9),
      author: {
        id: _mock.id(10),
        avatarUrl: _mock.image.avatar(index + 6),
        name: _mock.fullName(index + 6),
      },
      createdAt: _mock.time(3),
      message:
        'Etiam rhoncus. Nullam vel sem. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Sed lectus.',
    },
  ],
}));

export const _userCards = [...Array(21)].map((_, index) => ({
  id: _mock.id(index),
  role: _mock.role(index),
  name: _mock.fullName(index),
  coverUrl: _mock.image.cover(index),
  avatarUrl: _mock.image.avatar(index),
  totalFollowers: _mock.number.nativeL(index),
  totalPosts: _mock.number.nativeL(index + 2),
  totalFollowing: _mock.number.nativeL(index + 1),
}));

export const _userPayment = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  cardNumber: ['**** **** **** 1234', '**** **** **** 5678', '**** **** **** 7878'][index],
  cardType: ['mastercard', 'visa', 'visa'][index],
  primary: index === 1,
}));

export const _userAddressBook = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  primary: index === 0,
  name: _mock.fullName(index),
  phoneNumber: _mock.phoneNumber(index),
  fullAddress: _mock.fullAddress(index),
  addressType: (index === 0 && 'Home') || 'Office',
}));

export const _userInvoices = [...Array(10)].map((_, index) => ({
  id: _mock.id(index),
  invoiceNumber: `INV-199${index}`,
  createdAt: _mock.time(index),
  price: _mock.number.price(index),
}));

export const _userPlans = [
  {
    subscription: 'basic',
    price: 0,
    primary: false,
  },
  {
    subscription: 'starter',
    price: 4.99,
    primary: true,
  },
  {
    subscription: 'premium',
    price: 9.99,
    primary: false,
  },
];

export const _userList = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  zipCode: '85807',
  state: 'Virginia',
  city: 'Rancho Cordova',
  role: _mock.role(index),
  email: _mock.email(index),
  address: '908 Jack Locks',
  name: _mock.fullName(index),
  isVerified: _mock.boolean(index),
  company: _mock.companyName(index),
  country: countries[index + 1].label,
  avatarUrl: _mock.image.avatar(index),
  phoneNumber: _mock.phoneNumber(index),
  status:
    (index % 2 && 'pending') || (index % 3 && 'today') || (index % 4 && 'upcoming') || 'complete',
}));

export const _usersList = [
  {
    id: 1,
    name: 'Ali',
    email: 'ali@gmail.com',
    nationality: 'Pakistani',
    university: 'University of Karachi',
    status: 'active'
  },
  {
    id: 2,
    name: 'Nina',
    email: 'nina@gmail.com',
    nationality: 'American',
    university: 'Harvard University',
    status: 'inactive',
  },
  {
    id: 3,
    name: 'Liam',
    email: 'liam@gmail.com',
    nationality: 'British',
    university: 'University of Cambridge',
    status: 'active',
  },
  {
    id: 4,
    name: 'Lucy',
    email: 'lucy@gmail.com',
    nationality: 'Canadian',
    university: 'University of Toronto',
    status: 'inactive',
  },
  {
    id: 5,
    name: 'Carlos',
    email: 'carlos@gmail.com',
    nationality: 'Mexican',
    university: 'National Autonomous University of Mexico',
    status: 'active',
  },
  {
    id: 6,
    name: 'Hana',
    email: 'hana@gmail.com',
    nationality: 'Japanese',
    university: 'University of Tokyo',
    status: 'inactive',
  },
  {
    id: 7,
    name: 'Arjun',
    email: 'arjun@gmail.com',
    nationality: 'Indian',
    university: 'Indian Institute of Technology',
    status: 'active',
  },
  {
    id: 8,
    name: 'Marta',
    email: 'marta@gmail.com',
    nationality: 'Spanish',
    university: 'University of Barcelona',
    status: 'inactive',
  },
  {
    id: 9,
    name: 'Victor',
    email: 'victor@gmail.com',
    nationality: 'Russian',
    university: 'Lomonosov Moscow State University',
    status: 'active',
  },
  {
    id: 10,
    name: 'Ella',
    email: 'ella@gmail.com',
    nationality: 'Australian',
    university: 'University of Melbourne',
    status: 'inactive',
  },
  {
    id: 11,
    name: 'Tom',
    email: 'tom@gmail.com',
    nationality: 'American',
    university: 'Stanford University',
    status: 'active',
  },
  {
    id: 12,
    name: 'Linda',
    email: 'linda@gmail.com',
    nationality: 'British',
    university: 'University of Oxford',
    status: 'inactive',
  },
  {
    id: 13,
    name: 'Eric',
    email: 'eric@gmail.com',
    nationality: 'German',
    university: 'University of Heidelberg',
    status: 'active',
  },
  {
    id: 14,
    name: 'Anna',
    email: 'anna_smith@gmail.com',
    nationality: 'Canadian',
    university: 'McGill University',
    status: 'inactive',
  },
  {
    id: 15,
    name: 'Lucas',
    email: 'lucas@gmail.com',
    nationality: 'Brazilian',
    university: 'University of São Paulo',
    status: 'active',
  },
  {
    id: 16,
    name: 'Sara',
    email: 'sara_lee@gmail.com',
    nationality: 'South Korean',
    university: 'Seoul National University',
    status: 'inactive',
  },
  {
    id: 17,
    name: 'Wei',
    email: 'wei@gmail.com',
    nationality: 'Chinese',
    university: 'Tsinghua University',
    status: 'active',
  },
  {
    id: 18,
    name: 'Mia',
    email: 'mia_wang@gmail.com',
    nationality: 'Singaporean',
    university: 'National University of Singapore',
    status: 'inactive',
  },
  {
    id: 19,
    name: 'Ivan',
    email: 'ivan@gmail.com',
    nationality: 'Russian',
    university: 'Saint Petersburg State University',
    status: 'active',
  },
  {
    id: 20,
    name: 'Amelie',
    email: 'amelie@gmail.com',
    nationality: 'French',
    university: 'Sorbonne University',
    status: 'inactive',
  },
];

export const _agentsList = [
  {
    id: 1,
    name: 'Ali',
    email: 'ali@gmail.com',
    nationality: 'Pakistani',
    university: 'University of Karachi',
    status: 'active'
  },
  {
    id: 2,
    name: 'Nina',
    email: 'nina@gmail.com',
    nationality: 'American',
    university: 'Harvard University',
    status: 'inactive',
  },
  {
    id: 3,
    name: 'Liam',
    email: 'liam@gmail.com',
    nationality: 'British',
    university: 'University of Cambridge',
    status: 'active',
  },
  {
    id: 4,
    name: 'Lucy',
    email: 'lucy@gmail.com',
    nationality: 'Canadian',
    university: 'University of Toronto',
    status: 'inactive',
  },
  {
    id: 5,
    name: 'Carlos',
    email: 'carlos@gmail.com',
    nationality: 'Mexican',
    university: 'National Autonomous University of Mexico',
    status: 'active',
  },
  {
    id: 6,
    name: 'Hana',
    email: 'hana@gmail.com',
    nationality: 'Japanese',
    university: 'University of Tokyo',
    status: 'inactive',
  },
  {
    id: 7,
    name: 'Arjun',
    email: 'arjun@gmail.com',
    nationality: 'Indian',
    university: 'Indian Institute of Technology',
    status: 'active',
  },
  {
    id: 8,
    name: 'Marta',
    email: 'marta@gmail.com',
    nationality: 'Spanish',
    university: 'University of Barcelona',
    status: 'inactive',
  },
  {
    id: 9,
    name: 'Victor',
    email: 'victor@gmail.com',
    nationality: 'Russian',
    university: 'Lomonosov Moscow State University',
    status: 'active',
  },
  {
    id: 10,
    name: 'Ella',
    email: 'ella@gmail.com',
    nationality: 'Australian',
    university: 'University of Melbourne',
    status: 'inactive',
  },
  {
    id: 11,
    name: 'Tom',
    email: 'tom@gmail.com',
    nationality: 'American',
    university: 'Stanford University',
    status: 'active',
  },
  {
    id: 12,
    name: 'Linda',
    email: 'linda@gmail.com',
    nationality: 'British',
    university: 'University of Oxford',
    status: 'inactive',
  },
  {
    id: 13,
    name: 'Eric',
    email: 'eric@gmail.com',
    nationality: 'German',
    university: 'University of Heidelberg',
    status: 'active',
  },
  {
    id: 14,
    name: 'Anna',
    email: 'anna_smith@gmail.com',
    nationality: 'Canadian',
    university: 'McGill University',
    status: 'inactive',
  },
  {
    id: 15,
    name: 'Lucas',
    email: 'lucas@gmail.com',
    nationality: 'Brazilian',
    university: 'University of São Paulo',
    status: 'active',
  },
  {
    id: 16,
    name: 'Sara',
    email: 'sara_lee@gmail.com',
    nationality: 'South Korean',
    university: 'Seoul National University',
    status: 'inactive',
  },
  {
    id: 17,
    name: 'Wei',
    email: 'wei@gmail.com',
    nationality: 'Chinese',
    university: 'Tsinghua University',
    status: 'active',
  },
  {
    id: 18,
    name: 'Mia',
    email: 'mia_wang@gmail.com',
    nationality: 'Singaporean',
    university: 'National University of Singapore',
    status: 'inactive',
  },
  {
    id: 19,
    name: 'Ivan',
    email: 'ivan@gmail.com',
    nationality: 'Russian',
    university: 'Saint Petersburg State University',
    status: 'active',
  },
  {
    id: 20,
    name: 'Amelie',
    email: 'amelie@gmail.com',
    nationality: 'French',
    university: 'Sorbonne University',
    status: 'inactive',
  },
];

export const _stocksList = [
  {
    id: 1,
    stock_number: 8989754316,
    stock_amount: 10
  },
  {
    id: 2,
    stock_number: 4536789615,
    stock_amount: 20
  },
  {
    id: 3,
    stock_number: 6754567578,
    stock_amount: 30,
  }
];

export const _regionsList = [
  {
    id: 1,
    region: 'Lower Limb',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascial", "Ligaments"]
  },
  {
    id: 2,
    region: 'Upper Limb',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascial", "Ligaments"]
  },
  {
    id: 3,
    region: 'Abdomen',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascial", "Organs", "Endocrine", "Ligaments"]
  },
  {
    id: 4,
    region: 'Thorax',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascial", "Organs", "Endocrine", "Ligaments"]
  },
  {
    id: 5,
    region: 'Pelvis',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascia", "Organs", "Endocrine", "Ligaments"]
  },
  {
    id: 6,
    region: 'Back',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascia", "Ligaments"]
  },
  {
    id: 7,
    region: 'Head and Neck',
    system: ["Skeletal", "Vascular", "Muscular", "Nervous", "Fascia", "Organs", "Endocrine", "Ligaments"]
  }
];

export const _systemsList = [
  {
    id: 1,
    label: 'Skeletal',
    value: 'skeletal'
  },
  {
    id: 2,
    label: 'Vascular',
    value: 'vascular'
  },
  {
    id: 3,
    label: 'Muscular',
    value: 'muscular'
  },
  {
    id: 4,
    label: 'Nervous',
    value: 'nervous'
  },
  {
    id: 5,
    label: 'Fascial',
    value: 'fascial'
  },
  {
    id: 6,
    label: 'Ligaments',
    value: 'ligaments'
  },
  {
    id: 7,
    label: 'Organs',
    value: 'organs'
  },
  {
    id: 8,
    label: 'Endocrine',
    value: 'endocrine'
  },
  {
    id: 9,
    label: 'Fascia',
    value: 'fascia'
  }
];
export const _optionsList = [
  {
    id: 1,
    label: 'Option 1',
    value: 'option_1'
  },
  {
    id: 2,
    label: 'Option 2',
    value: 'option_2'
  },
  {
    id: 3,
    label: 'Option 3',
    value: 'option_3'
  },
  {
    id: 4,
    label: 'Option 4',
    value: 'option_4'
  }
];


