import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./Reducer/auth";
import userReducer from "./slices/userSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { productsApi } from "./Reducer/products";
import { categoryApi, } from "./Reducer/category";
import { customersApi } from "./Reducer/customers";
import { serviceApi } from "./Reducer/service";
import { equipmentApi } from "./Reducer/equipment";
import { bookingApi } from "./Reducer/booking";
import { FileApi } from "./Reducer/file";
import { equipmentOrdersApi } from "./Reducer/equipmentOrders";
import { specialOffersApi } from "./Reducer/specialOffers";
import { FaqsApi } from "./Reducer/faqs";
import { SettingsApi } from "./Reducer/settings";
import { supportApi } from "./Reducer/support";
import { dashboardApi } from "./Reducer/dashboard"
import { usersAPI } from "./Reducer/users";
import { regionApi } from "./Reducer/regions";
import { systemApi } from "./Reducer/system";
import { questionApi } from "./Reducer/question";
import { subscriptionApi } from "./Reducer/subscriptions";


// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['user'],
};

// Create a persisted reducer for the user slice
const persistedUserReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store with the persisted reducer for the user slice
export const store = configureStore({
  reducer: {
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [FileApi.reducerPath]: FileApi.reducer,
    [equipmentOrdersApi.reducerPath]: equipmentOrdersApi.reducer,
    [specialOffersApi.reducerPath]: specialOffersApi.reducer,
    [FaqsApi.reducerPath]: FaqsApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [systemApi.reducerPath]: systemApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [subscriptionApi.reducerPath]:subscriptionApi.reducer,
    user: persistedUserReducer, // Use the persisted user reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productsApi.middleware,
      authApi.middleware,
      categoryApi.middleware,
      customersApi.middleware,
      serviceApi.middleware,
      equipmentApi.middleware,
      bookingApi.middleware,
      FileApi.middleware,
      equipmentOrdersApi.middleware,
      specialOffersApi.middleware,
      FaqsApi.middleware,
      SettingsApi.middleware,
      supportApi.middleware,
      dashboardApi.middleware,
      usersAPI.middleware,
      regionApi.middleware,
      systemApi.middleware,
      questionApi.middleware,
      subscriptionApi.middleware
    ),
});

// Initialize Redux Persist
export const persistor = persistStore(store);

// Setup listeners
setupListeners(store.dispatch);
