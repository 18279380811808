import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";


export const SettingsApi = createApi({
    reducerPath: "settings",
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['about', 'privacy', 'tac'],
    endpoints: (builder) => ({
        getAbout: builder.query({
            query: () => ({
                url: `admin/about`,
                method: "GET",
                transformResponse: (res) => res.data,
            }),
            providesTags: ['about'],
        }),

        updateAbout: builder.mutation({
            query: (text) => {
                console.log("text", text)
                return {
                    url: `admin/about`,
                    method: "PUT",
                    body: { about: text },
                }
            },
            invalidatesTags: ['about'],
        }),

        getPrivacy: builder.query({
            query: () => ({
                url: `admin/privacy`,
                method: "GET",
                transformResponse: (res) => res.data,
            }),
            providesTags: ['privacy'],
        }),

        updatePrivacy: builder.mutation({
            query: (text) => {
                console.log("text", text)
                return {
                    url: `admin/privacy`,
                    method: "PUT",
                    body: { privacy: text },
                }
            },
            invalidatesTags: ['privacy'],
        }),

        getTerms: builder.query({
            query: () => ({
                url: `admin/tac`,
                method: "GET",
                transformResponse: (res) => res.data,
            }),
            providesTags: ['tac'],
        }),

        updateTerms: builder.mutation({
            query: (text) => {
                console.log("text", text)
                return {
                    url: `admin/tac`,
                    method: "PUT",
                    body: { tac: text },
                }
            },
            invalidatesTags: ['tac'],
        }),
    }),
});

export const {
    useGetAboutQuery,
    useUpdateAboutMutation,
    useGetPrivacyQuery,
    useUpdatePrivacyMutation,
    useGetTermsQuery,
    useUpdateTermsMutation
} = SettingsApi;
