import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const questionApi = createApi({
    reducerPath: 'question',
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Question'],
    endpoints: (builder) => ({
        getAllQuestions: builder.query({
            query: ({ pageno, limit, search, system, region }) => `question/all?pageno=${pageno + 1}&limit=${limit}&search=${search}&system=${system}&region=${region}`,
            transformResponse: (res) => (res.body),
            providesTags: ['Question'],
        }),

        // getCategoriesDropdown: builder.query({
        //   query: () => `/service/categories/user`,
        //   transformResponse: (res) => res.body,
        // }),

        // deleteProduct: builder.mutation({
        //     query: (id) => ({
        //       url: `/products/${id}`,
        //       method: "DELETE",
        //     }),

        getQuestionById: builder.query({
            query: (id) => `/question/single/${id}`,
            transformResponse: (res) => (res.body),
            providesTags: ['Question'],

        }),

        addNewQuestion: builder.mutation({
            query: (newQuestion) => ({
                url: `/question/add`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: newQuestion,
            }),
            invalidatesTags: ['Question'],
        }),

        updateQuestion: builder.mutation({
            query: (data) =>
            (
                {
                    url: `question/update/${data._id}`,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: data,
                }
            ),
            invalidatesTags: ['Question'],
        }),

        deleteQuestion: builder.mutation({
            query: (id) =>
            (
                {
                    url: `/question/delete/${id}`,
                    method: "DELETE"
                }
            ),
            invalidatesTags: ['Question']
        })
    }),
});

export const {
    useGetQuestionByIdQuery,
    useGetAllQuestionsQuery,
    useAddNewQuestionMutation,
    useUpdateQuestionMutation,
    useDeleteQuestionMutation
} = questionApi;
