import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const systemApi = createApi({
  reducerPath: 'system',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['System'],
  endpoints: (builder) => ({
    getAllSystem: builder.query({
      query: ({ pageno, limit, search }) => `system/all?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => (res.body),
      providesTags: ['System'],
    }),
    getSystemDropdown: builder.query({
      query: () => `/system/all`,
      transformResponse: (res) => res.body,
    }),

    addNewSystem: builder.mutation({
      query: (newSystem) => ({
        url: `/system/add`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newSystem,
      }),
      invalidatesTags: ['System'],
    }),

    updateSystem: builder.mutation({
      query: (data) => (
        {
          url: `system/update/${data._id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        }
      ),
      invalidatesTags: ['System'],
    }),
    deleteSystem: builder.mutation({
      query: (id) =>
      (
          {
              url: `/system/delete/${id}`,
              method: "DELETE"
          }
      ),
      invalidatesTags:['System']
  })
  }),
});

export const {
  useGetAllSystemQuery,
  useGetSystemDropdownQuery,
  useAddNewSystemMutation,
  useUpdateSystemMutation,
  useDeleteSystemMutation
  // useGetCategoriesBySearchQuery
} = systemApi;
