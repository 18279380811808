import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const usersAPI = createApi({
  reducerPath: 'Users',
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ pageno, limit, search }) =>
        `auth/all/?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.body.total_pages, data: res.body.users, total_length: res.body.total_length }),
      providesTags: ['User'],
    }),

    getUserById: builder.query({
      query: (id) => `/auth/single/${id}`,
      transformResponse: (res) => (res.body),
      providesTags: ['User'],
    }),

    // getBookingWithFilterPending: builder.query({
    //   query: (id) => `booking/filter?filter=pending`,
    //   transformResponse: (res) => res.body,
    // }),

    updateUser: builder.mutation({
      query: (data) => (
        console.log('On API : ', data, data?._id),
        {
          url: `auth/userUpdate/${data?._id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        }
      ),
      invalidatesTags: ['User'],
    }),

  }),
});

export const { useGetAllUsersQuery, useGetUserByIdQuery, useUpdateUserMutation } = usersAPI;
